import { createContext, useState, useEffect } from "react"
import axios from "axios"

const StaticContext = createContext({})

export default StaticContext

export function StaticContextProvider(props) {
	const [statics, setStatics] = useState([])

	useEffect(() => {
		const url = `${process.env.REACT_APP_API_URL}StaticProductData.php`

		const api = axios.create()
		api.get(url).then((response) => {
			setStatics({ ...response.data })
		})
	}, [])

	return <StaticContext.Provider value={{ statics }}>{props.children}</StaticContext.Provider>
}
