import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import { StaticContextProvider } from "./Context/Static.Provider"

const container = document.getElementById("app")

const root = createRoot(container)
root.render(
	<StaticContextProvider>
		<App tab="home" />
	</StaticContextProvider>
)