import { useState, useEffect, useContext } from "react"
import StaticContext from "./Context/Static.Provider"
import { BrowserRouter as Router } from "react-router-dom"
import axios from "axios"
import "./Styles/Reset.css"
import "./App.sass"
import "./Styles/Media.sass"
import AsianLogo from "./Assets/asian-street-food-lotus.svg"
import IconLightBulb from "./Assets/icon-lighbulb.svg"
import ProductItem from "./Components/ProductItem"
import { useMediaQuery } from "react-responsive"
import Menus from "./MenusData.json"

const QuantityMessage = () => {
	return (
		<div className="quantity-message">
			<img src={IconLightBulb} alt="Asian Street Food à Berck" />
			<p>Une bonne portion pour une personne varie entre 150 et 200 grammes</p>
		</div>
	)
}

function App(props) {
	const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
	const [openedPopup, setOpenedPopup] = useState(null)

	const [products, setProducts] = useState([])

	const { statics } = useContext(StaticContext)
	const [allergiesList, setAllergiesList] = useState([])
	const [packagingsList, setPackagingsList] = useState([])
	const [meatList, setMeatList] = useState([])
	const [tagsList, setTagsList] = useState([])
	useEffect(() => {
		setAllergiesList(statics.allergies)
		setPackagingsList(statics.packagings)
		setMeatList(statics.meats)
		setTagsList(statics.tags)
	}, [statics])

	const getProducts = (search) => {
		const url = `${process.env.REACT_APP_API_URL}GetProducts.php`

		const api = axios.create()
		api.get(url).then((response) => {
			setProducts(response.data)
		})
	}

	const [activeMeatFilter, setActiveMeatFilter] = useState(JSON.parse(localStorage.getItem("activeMeatFilter")) || [])
	const [activeAllergiesFilter, setActiveAllergiesFilter] = useState(JSON.parse(localStorage.getItem("activeAllergiesFilter")) || [])

	const handleFilter = (type, newFilter) => {
		if (type === "meatFilter") {
			const newMeatFilter =
				activeMeatFilter.findIndex((meat) => meat.id === newFilter.id) !== -1
					? activeMeatFilter.filter((meat) => meat.id !== newFilter.id)
					: [...activeMeatFilter, newFilter]
			localStorage.setItem("activeMeatFilter", JSON.stringify(newMeatFilter))
			setActiveMeatFilter(newMeatFilter)
		} else {
			const newAllergyFilter =
				activeAllergiesFilter.findIndex((allergy) => allergy.id === newFilter.id) !== -1
					? activeAllergiesFilter.filter((allergy) => allergy.id !== newFilter.id)
					: [...activeAllergiesFilter, newFilter]
			localStorage.setItem("activeAllergiesFilter", JSON.stringify(newAllergyFilter))
			setActiveAllergiesFilter(newAllergyFilter)
		}
	}

	useEffect(() => {
		getProducts()
	}, [])

	const handleNav = (tab) => {
		console.log(tab)
		const offset = isMobile ? 40 : 0
		const bodyRect = document.body.getBoundingClientRect().top
		const elementRect = document.getElementById(tab).getBoundingClientRect().top
		const elementPosition = elementRect - bodyRect
		const offsetPosition = elementPosition - offset

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		})
	}

	return (
		<Router>
			<header>
				{isMobile && (
					<div className="mobile-nav" onClick={() => setOpenedPopup((prev) => (prev ? null : "navigation"))}>
						<span></span>
					</div>
				)}
				<div className={`logo`}>
					<img src={AsianLogo} alt="Asian Street Food à Berck" />
					<h1>Asian Street Food</h1>
				</div>
				<p className="subtitle">Bangkok · Saigon · Chaoshan · Séoul · Tokyo</p>
			</header>
			<section className="subheader">
				<p className="title title-underline center">Découvrez</p>
				<p className="main-title">Notre carte</p>
			</section>
			<main className="main-wrapper">
				<aside>
					<div
						className={`nav-wrap${isMobile ? " nav-popup" : ""}${openedPopup === "navigation" ? " opened" : ""}`}
						onClick={() => setOpenedPopup((prev) => (prev ? null : "navigation"))}>
						<ul className={`nav`}>
							{products.map((el) => (
								<li key={el.id} onClick={() => handleNav(el.value)}>
									{el.label}
								</li>
							))}
						</ul>
					</div>
					<div className="filters">
						<div className="filter filter-meat">
							<p className="filter-title">Je souhaite des plats sans&nbsp;:</p>
							<ul>
								{meatList?.map((meat) => (
									<li
										key={meat.id}
										className={`checkbox ${activeMeatFilter.some((filter) => filter.id === meat.id) && "active"}`}
										onClick={() => handleFilter("meatFilter", meat)}>
										<span></span>
										<p>{meat.label}</p>
									</li>
								))}
							</ul>
						</div>
						<div className="filter filter-allergie">
							<p className="filter-title">Je suis allergique au&nbsp;:</p>
							<ul>
								{allergiesList?.map((allergy) => (
									<li
										key={allergy.id}
										className={`checkbox ${activeAllergiesFilter.some((filter) => filter.id === allergy.id) && "active"}`}
										onClick={() => handleFilter("AllergiesFilter", allergy)}>
										<span></span>
										<p>{allergy.label}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</aside>
				<section className="products-list">
					{products.map((cat) => (
						<>
							<article key={cat.id} id={cat.value}>
								<p className="title title-underline">
									<span>{cat.label}</span>
								</p>
								{cat.id === "13" ? (
									<>
										<div className="col-2">
											{Menus.map((menu, index) => (
												<div className="menu" key={index}>
													<div className="menu-head">
														<p className="menu-name">
															<em>Menu {menu.label}</em>
														</p>
														<p className="menu-price">{parseFloat(menu.price).toFixed(2).replace(".", ",")}€</p>
													</div>
													{menu.composition.map((compo, index) => (
														<div key={index} className="menu-detail">
															<p className="menu-detail-title">{compo.label}</p>
															<ul>
																{compo.dishes.map((dish, index) => (
																	<li key={index}>{dish}</li>
																))}
															</ul>
														</div>
													))}
												</div>
											))}
										</div>
									</>
								) : cat.id === "2" ? (
									<>
										{/* If is japanese sushi display it in 1 col */}
										<div className="col-1">
											{cat.products
												.filter((p) => p.status === "1" && p.image)
												.map((product) => (
													<ProductItem
														key={product.id}
														{...product}
														productPackaging={packagingsList?.find((packaging) => packaging?.id === product?.packaging)}
														productTags={product.tag?.map((pTag) => tagsList?.find((tag) => tag.id === pTag))}
														checkMeat={
															!product.meat ||
															product.meat?.some((e) => activeMeatFilter.map((el) => el.id).indexOf(e) === -1)
														}
														checkAllergen={
															!product.allergy ||
															product.allergy?.some((e) => activeAllergiesFilter.map((el) => el.id).indexOf(e) !== -1)
														}
													/>
												))}
										</div>

										{/* Else display it in 2 col */}
										<div className="col-2">
											{cat.products
												.filter((p) => p.status === "1" && !p.image)
												.map((product) => (
													<ProductItem
														key={product.id}
														{...product}
														productPackaging={packagingsList?.find((packaging) => packaging?.id === product?.packaging)}
														productTags={product.tag?.map((pTag) => tagsList?.find((tag) => tag.id === pTag))}
														checkMeat={
															!product.meat ||
															product.meat?.some((e) => activeMeatFilter.map((el) => el.id).indexOf(e) === -1)
														}
														checkAllergen={
															!product.allergy ||
															product.allergy?.some((e) => activeAllergiesFilter.map((el) => el.id).indexOf(e) !== -1)
														}
													/>
												))}
										</div>
									</>
								) : (
									cat.products
										.filter((p) => p.status === "1")
										.map((product) => (
											<ProductItem
												key={product.id}
												{...product}
												productPackaging={packagingsList?.find((packaging) => packaging?.id === product?.packaging)}
												productTags={product.tag?.map((pTag) => tagsList?.find((tag) => tag.id === pTag))}
												checkMeat={
													!product.meat?.length ||
													product.meat?.some((e) => activeMeatFilter.map((activeMeat) => activeMeat.id).indexOf(e) === -1)
												}
												checkAllergen={
													!product.allergy ||
													product.allergy?.some((e) => activeAllergiesFilter.map((el) => el.id).indexOf(e) !== -1)
												}
											/>
										))
								)}
								{/* {cat.id === "3" && <QuantityMessage />} */}
							</article>
						</>
					))}
				</section>
			</main>
		</Router>
	)
}

export default App
