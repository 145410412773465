import IconCookingTime from "../Assets/cooking-time.svg"
import IconSpicy from "../Assets/spicy.svg"
import IconVegan from "../Assets/vegan.svg"

export const ProductItem = ({...props}) => {
	return (
		<div
			className={`product${!props.checkMeat || props.checkAllergen ? " hide" : ""}${
				props.image && props.category === "2" ? " product-sushi" : ""
			}`}>
			{props.image ? (
				<>
					<img src={`${process.env.REACT_APP_IMG_PATH}${props.image}`} alt={props.name_fr} width="200" className="product-sushi-img" />
					<p className="product-sushi-name">
						{props.name_fr}&nbsp;-&nbsp;
						<span className="product-sushi-price">{parseFloat(props.price).toFixed(2).replace(".", ",")}€</span>
					</p>
					{props.description && <div className="product-sushi-description">{props.description}</div>}
				</>
			) : (
				<>
					<div className="product-head">
						<p className="product-name">
							<em>
								{props.name_fr}&nbsp;
								{/* If have spicy tag */}
								{props.productTags?.some((tag) => tag?.id === "3") && (
									<>
										<img src={IconSpicy} alt="" />
										&nbsp;
									</>
								)}
								{props.productTags?.some((tag) => tag?.id === "2") && (
									<>
										<img src={IconVegan} alt="" />
										&nbsp;
									</>
								)}
								{/* If have cooking time */}
								{props.cooking_time > 0 && (
									<span className="product-cooking_time">
										<img src={IconCookingTime} alt="" />~{props.cooking_time} min
									</span>
								)}
							</em>
						</p>
						{props.description && <div className="product-description">{props.description}</div>}
					</div>
					<p className="product-price">{parseFloat(props.price).toFixed(2).replace(".", ",")}€</p>
					<p className="product-type">
						{props.productPackaging?.id === "2"
							? "100gr"
							: `${props.piece > 0 ? props.piece : ""} ${
									props.productPackaging?.id === "5" ? props.custom_packaging : props.productPackaging?.label
							  }${props.piece > 1 ? "s" : ""}`}
					</p>
				</>
			)}
		</div>
	)
}

export default ProductItem
